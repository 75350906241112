<template>
    <section class="zonas-listado-zonas">
        <cargando v-if="cargando" />
        <titulo2 titulo="Zonas comunes" class="d-none">
            <div class="row justify-content-end">
                <button class="btn-general btn btn-sm f-14 px-4 f-300" @click="crearZona">
                    Crear zona
                </button>
            </div>
        </titulo2>
        <div class="row mb-4">
            <div class="col-12 col-lg-auto">
                <p class="f-20 f-500">Zonas comunes</p>
            </div>
            <div class="col-auto ml-auto my-auto">
                <button class="btn-general btn btn-sm f-14 px-4 f-300" @click="crearZona">
                    Crear zona
                </button>
            </div>
            <div class="col-auto">
                <el-input v-model="search" placeholder="Buscar" prefix-icon="el-icon-search" size="small" @change="listar" />
            </div>
        </div>
        <div v-loading="loading" class="row overflow-auto custom-scroll" style="height:calc(100vh - 206px)">
            <div v-for="(zona,i) in zonas" :key="i" class="col-auto" @click="editarZona(zona.id)">
                <div class="card-zona">
                    <div class="container-img">
                        <img v-if="zona.imagenes.length" :src="zona.imagenes[0].imagen" alt="" class="obj-cover h-100 w-100" />
                        <img v-else src="/img/no-imagen/noimagenzonas.svg" alt="" class="obj-cover h-100 w-100" />
                    </div>
                    <div class="row mx-0 container-info pb-1 align-content-start">
                        <p class="col-12 f-15 my-1">{{ zona.nombre }}</p>
                        <div class="col-auto my-1">
                            <div v-show="zona.bloques_vivienda" class="categoria d-middle br-20 px-2 f-12 bg-activo">
                                <p class="tres-puntos op-05">Maximo {{ zona.bloques_vivienda + ` ${zona.bloques_vivienda > 1 ? 'bloques' : 'bloque'}` }}</p>
                            </div>
                            <!-- preguntar a Andrés -->
                            <div v-show="!zona.bloques_vivienda" class="categoria d-middle br-20 px-2 f-12 bg-activo">
                                <p class="tres-puntos op-05">Sin restricción de uso</p>
                            </div>
                        </div>
                        <div class="col-auto ml-auto my-1">
                            <div v-show="!zona.estado" class="categoria d-middle br-20 px-2 f-12 bg-db">
                                <p class="tres-puntos op-05">Inactiva</p>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="d-flex">
                                <!-- agregar bg-general o bg-rojo-claro segun el estado -->
                                <div class="dia d-middle-center rounded-circle f-12 mr-1" :class="cerrado(1,zona.dias_cerrado) ? 'bg-rojo-claro' : 'bg-general'">
                                    D
                                </div>
                                <div class="dia d-middle-center rounded-circle f-12 mr-1" :class="cerrado(2,zona.dias_cerrado) ? 'bg-rojo-claro' : 'bg-general'">
                                    L
                                </div>
                                <div class="dia d-middle-center rounded-circle f-12 mr-1" :class="cerrado(3,zona.dias_cerrado) ? 'bg-rojo-claro' : 'bg-general'">
                                    M
                                </div>
                                <div class="dia d-middle-center rounded-circle f-12 mr-1" :class="cerrado(4,zona.dias_cerrado) ? 'bg-rojo-claro' : 'bg-general'">
                                    M
                                </div>
                                <div class="dia d-middle-center rounded-circle f-12 mr-1" :class="cerrado(5,zona.dias_cerrado) ? 'bg-rojo-claro' : 'bg-general'">
                                    J
                                </div>
                                <div class="dia d-middle-center rounded-circle f-12 mr-1" :class="cerrado(6,zona.dias_cerrado) ? 'bg-rojo-claro' : 'bg-general'">
                                    V
                                </div>
                                <div class="dia d-middle-center rounded-circle f-12 mr-1" :class="cerrado(7,zona.dias_cerrado) ? 'bg-rojo-claro' : 'bg-general'">
                                    S
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <p v-show="zona.pago_valor" class="d-middle f-12">
                                <i class="icon-money-outline f-15 mr-2 op-05" />
                                {{ convertMoney(zona.pago_valor) }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <infinite-loading @infinite="listar">
                    <div slot="spinner">
                        Consultando...
                    </div>
                    <div slot="no-more">
                        No hay más zonas
                    </div>
                    <div slot="no-results" />
                </infinite-loading>
            </div>
        </div>
    </section>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading';
import Zonas from '~/services/zonas'
export default {
    components:{
        InfiniteLoading
    },
    data(){
        return{
            search:'',
            page: 1,
            zonas:[],
            loading : false,
            cargando: false
        }
    },
    watch:{
        search(){
            this.page = 1
            this.zonas = []
        }
    },
    methods:{
        crearZona(){
            this.$router.push({ name: 'zonas.crear.nueva' })
        },
        editarZona(id_zona){
            this.$router.push({ name: 'zonas.guardar.zona', params:{id_zona : id_zona, editar: true } })
        },
        async listar($state){
            try {
                const state = $state
                this.cargando = true
                let params= {
                    page: this.page,
                    search: this.search,
                }
                const {data} = await Zonas.listar(params)
                if(data.data.data.length){
                    this.page += 1;
                    this.zonas.push(...data.data.data)
                    if(typeof state !== 'undefined'){
                        state.loaded();
                    }
                }else{
                    if(typeof state !== 'undefined'){
                        state.complete();
                    }
                }            
            } catch (e){
                this.errorCatch(e)
            } finally {
                this.cargando = false
            }
        },
        cerrado(num,dias_cerrados){
            return dias_cerrados.includes(num)
        }
    }
}
</script>
<style lang="scss" scoped>
.zonas-listado-zonas{
    .card-zona{
        max-width: 330px;
        border-radius: 8px;
        position: relative;
        cursor: pointer;
        position: relative;
        .container-img{
            height: 182px;
            overflow: hidden;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        .container-info{
            // min-height: 120px;
            height: 145px;
            border-radius: 8px;
            border:1px solid #DBDBDB;
            position: relative;
            top: -8px;
            background: white;
            .categoria{
                min-height: 22px;
            }
            .dia{
                min-width: 22px;
                min-height: 22px;
                max-width: 22px;
                max-height: 22px;
                background: var(--color-db);
            }
        }
    }
}
</style>